import React, { useContext, useRef } from "react";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { LuChevronLast } from "react-icons/lu";
import { IoChevronDown } from "react-icons/io5";
import { httpReauest } from "../utils/httpRequest";
import { useEffect } from "react";
import { useState } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { toast } from "react-toastify";
import { userContext } from "../context/userContext";
import { IoMdFastforward } from "react-icons/io";
import moment from "moment/moment";
import { IoMdClose } from "react-icons/io";
const Modal = ({
  userFriendlyAddress,
  data,
  modal,
  getUser,
  open,
  lastrounds,
  winner,
  number,
  getImagesFromNumberString,
  setWinner,
  setRoundDetail,
  roundDetail,
  setRoundId,
  roundId,
  rounds,
  active,
  number1,
  setNumber1,
  setModal
}) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const { setData, id, setId } = useContext(userContext);
  const [round, setRound] = useState(0);
  const [winner1,setWinner1] = useState([])

  async function getRoundDtail(id) {
    const response = await httpReauest(
      "GET",
      `/round/detail/${id}`,
      {},
      {}
    ).then((e) => {
      if (e.status === 200) {
        console.log(e);
        setRoundDetail(e?.data?.data);
        setRoundId(e?.data?.data?.id);
        const winner = getImagesFromNumberString(e?.data?.data?.winner_number);
        setWinner(winner);
      }
    });
  }

  useEffect(() => {
    if (modal) {
      const yournumber = getImagesFromNumberString(
        rounds[round]?.number
      );
      setNumber1(yournumber);
      const winner = getImagesFromNumberString(
        rounds[round]?.round?.winner_number
      );
      setWinner1(winner);
    }
  }, [round]);

  useEffect(() => {
    setRoundDetail(lastrounds);
    setRoundId(lastrounds?.id);
  }, []);

  const transactio2 = {
    validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
    messages: [
      {
        address: "UQB9ppaJGykOk2DR8zFIWqSQPAyMyMetm-lmOIcCgeefimdx", // message destination in user-friendly format
        amount: "500000000", // Toncoin in nanotons
      },
    ],
  };

  async function sendDouble(e) {
    console.log(e?._id);
    const response = await httpReauest(
      "POST",
      `/round/ticket`,
      { userId: data?.user?._id, roundId: e?._id },
      {}
    );
  }

  async function handleBuyTicket(e) {
    try {
      if (!userFriendlyAddress) {
        return toast.error("Connect Your Wallet First");
      }
      const response = await tonConnectUI.sendTransaction(transactio2);
      // بررسی وضعیت پاسخ
      if (response) {
      await sendDouble(e).then((res) => {
        toast.success("Successfull");
        getUser();
      });
      } else {
      toast.error("Transaction failed. Please try again.");
      }
    } catch (error) {
      console.error("Error sending transaction:", error);
      toast.error(
        "An error occurred while sending the transaction. Please try again."
      );
    }
  }
  console.log(rounds);
  return (
    <div className="bg-[#4F04A5] relative flex flex-col items-center p-5 rounded-3xl">
      <button onClick={()=>{setModal(false)}} className="absolute top-3 right-3"><IoMdClose size={30}/></button>
      <h2 className="text-[23px] border-sec-2 my-2">
        {active?.buyed ? "Finished Rounds" : "Buy tickets"}
      </h2>
      <div className="flex flex-col items-center bg-[#6706BF] rounded-3xl border-r w-full mt-3 border-[#fff]/30">
        <p className="text-[14px] bg-[#5e08c1] rounded-2xl px-3 py-1 mt-[-10px]">
          Your History
        </p>
        {active?.buyed ? (
          <>
            <div className="flex w-full items-center justify-between px-4 mt-5">
              <span className="flex justify-center items-center gap-1">
                <h3 className="border-sec-3 text-[13px] pl-2">Rounds</h3>
                <span className="bg-[#9042e9] px-2 text-[9px] text-[#FFF]/90 font-[100] rounded-3xl">
                  {rounds[round]?.round?.id}
                </span>
              </span>
              <span className="flex  items-center justify-around gap-5">
                <FaChevronLeft
                  onClick={() => {
                    if (round >= 0) {
                      setRound(round + 1);
                      
                    }
                  }}
                  size={12}
                  color={round >= 0 ? "#ffffff" : "#ffffff7e"}
                />
                <FaChevronRight
                  onClick={() => {
                    if (round !== 0) {
                      setRound(round - 1);
                      
                    }
                  }}
                  size={12}
                  color={round !== 0 ? "#ffffff" : "#ffffff7e"}
                />
                <LuChevronLast
                  onClick={() => {
                    if (round !== 0) {
                      setRound(0);
                      
                    }
                  }}
                  size={17}
                  color={round !== 0 ? "#ffffff" : "#ffffff7e"}
                />
              </span>
            </div>
            <div className="w-[90%] text-[8px] text-[#FFF]/40  font-[100] border-b border-[#FFF]/20 py-1  my-1">
              {moment(lastrounds?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </div>
            {/* {lastrounds.number && (
      <>
        <h3 className="text-[10px] border-sec mt-4">Your Number</h3>
        <div className="flex justify-center items-center mt-2 mb-4 gap-1">
          {number.map((e) => (
            <img className="w-[45px] " src={e} />
          ))}
        </div>
      </>
    )} */}
            <h3 className="text-[10px] border-sec mt-4">Your Number</h3>
            <div className="flex justify-center items-center mt-2 mb-4 gap-1">
              {number1.map((e) => (
                <img className="w-[45px] " src={e} />
              ))}
            </div>
            <h3 className="text-[10px] border-sec mt-4">Winner Number</h3>
            <div className="flex justify-center items-center mt-2 mb-4 gap-1">
              {winner1.map((e) => (
                <img className="w-[45px] " src={e} />
              ))}
            </div>

            {show1 && (
              <div className="flex flex-col w-full">
                <h3 className="px-4 mb-2">{rounds?.fee}Fee</h3>
                <div className="grid h-[100px] overflow-auto gap-4 px-4 grid-cols-2 w-full">
                  {rounds[round]?.round?.winnerDetails?.map((e) => (
                    <div className="flex text-[11px] flex-col">
                      <span className="">Match first {e?.match} </span>
                      <span className="text-[9px] text-[#fff]/70">
                        {e?.totalPrize} TON{" "}
                      </span>
                      <span className="text-[9px] text-[#fff]/70">
                        {e?.prizePerTicket} TON each{" "}
                      </span>
                      <span className="text-[9px] text-[#fff]/70">
                        {e?.winningTickets} Winningtickets{" "}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <p
              onClick={() => {
                setShow1(!show1);
              }}
              className="text-[10px] bg-[#5e08c1] rounded-2xl px-3 py-1 mb-[-10px] flex items-center gap-1"
            >
              {show1 ? "Hide" : "Dtail"}{" "}
              <IoChevronDown
                style={{ rotate: show1 ? "180deg" : "0deg" }}
                size={12}
              />
            </p>
          </>
        ) : (
          <>
            <p className="text-[13px] border-x-[2px] border-[#EEB92E] mt-8 px-1 flex items-center h-3 ">
              Rounds {active?.id}
            </p>

            <span className="flex items-center border-sec gap-1 mt-[10px] text-[9px]">
              <p className="text-[#fff]/60">Connect your wallet to </p>
              <p>check your history</p>
            </span>

            <button className="rounded-[51px] w-[70%] flex justify-between items-center mt-4 py-[6px]  bg-[#7903ff] text-[16px] px-6 mb-2">
              <p>1 Tickets</p>
              <p>1 TON</p>
            </button>

            {userFriendlyAddress ? (
              <button
                onClick={() => {
                  handleBuyTicket(active);
                }}
                className="rounded-[51px] w-[70%] bg-[#7903ff] text-[16px] py-[6px] px-2 mb-[40px]"
              >
                Buy tickets
              </button>
            ) : (
              <button
                onClick={open}
                className="rounded-[51px] w-[70%] bg-[#7903ff] text-[16px] py-[6px] px-2 mb-[40px]"
              >
                Connect Wallet
              </button>
            )}
            <span className="w-[90%] border-t border-[#fff]/20 text-[6px] text-[#FFF]/40 text-center mx-[20px] my-4 p-1">
              only showing data for lottery V2
            </span>
          </>
        )}
      </div>
      <div className="flex flex-col items-center bg-[#6706BF] rounded-3xl border-r w-full mt-8 border-[#fff]/30">
        <p className="text-[14px] bg-[#5e08c1] rounded-2xl px-3 py-1 mt-[-10px]">
          All History
        </p>
        <div className="flex w-full items-center justify-between px-4 mt-5">
          <span className="flex justify-center items-center gap-1">
            <h3 className="border-sec-3 text-[13px] pl-2">Rounds</h3>
            <span className="bg-[#9042e9] px-2 text-[9px] text-[#FFF]/90 font-[100] rounded-3xl">
              {roundId}
            </span>
          </span>
          <span className="flex  items-center justify-around gap-5">
            <FaChevronLeft
              onClick={() => {
                if (roundId > 1) {
                  getRoundDtail(roundId - 1);
                }
              }}
              size={12}
              color={roundId > 1 ? "#ffffff" : "#ffffff7e"}
            />
            <FaChevronRight
              onClick={() => {
                if (roundId < lastrounds?.id) {
                  getRoundDtail(roundId + 1);
                } else {
                  return;
                }
              }}
              size={12}
              color={roundId < lastrounds?.id ? "#ffffff" : "#ffffff7e"}
            />
            <LuChevronLast
              onClick={() => {
                if (roundId !== lastrounds?.id) {
                  getRoundDtail(lastrounds?.id);
                }
              }}
              size={17}
              color={roundId !== lastrounds?.id ? "#ffffff" : "#ffffff7e"}
            />
          </span>
        </div>
        <div className="w-[90%] text-[8px] text-[#FFF]/40  font-[100] border-b border-[#FFF]/20 py-1  my-1">
          {moment(lastrounds?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </div>
        {/* {lastrounds.number && (
          <>
            <h3 className="text-[10px] border-sec mt-4">Your Number</h3>
            <div className="flex justify-center items-center mt-2 mb-4 gap-1">
              {number.map((e) => (
                <img className="w-[45px] " src={e} />
              ))}
            </div>
          </>
        )} */}
        <h3 className="text-[10px] border-sec mt-4">Wining Number</h3>
        <div className="flex justify-center items-center mt-2 mb-4 gap-1">
          {winner.map((e) => (
            <img className="w-[45px] " src={e} />
          ))}
        </div>

        {show && (
          <div className="flex flex-col w-full">
            <h3 className="px-4 mb-2">{roundDetail?.fee}Fee</h3>
            <div className="grid h-[100px] overflow-auto gap-4 px-4 grid-cols-2 w-full">
              {roundDetail?.winnerDetails?.map((e) => (
                <div className="flex text-[11px] flex-col">
                  <span className="">Match first {e?.match} </span>
                  <span className="text-[9px] text-[#fff]/70">
                    {e?.totalPrize} TON{" "}
                  </span>
                  <span className="text-[9px] text-[#fff]/70">
                    {e?.prizePerTicket} TON each{" "}
                  </span>
                  <span className="text-[9px] text-[#fff]/70">
                    {e?.winningTickets} Winningtickets{" "}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        <p
          onClick={() => {
            setShow(!show);
          }}
          className="text-[10px] bg-[#5e08c1] rounded-2xl px-3 py-1 mb-[-10px] flex items-center gap-1"
        >
          {show ? "Hide" : "Dtail"}{" "}
          <IoChevronDown
            style={{ rotate: show ? "180deg" : "0deg" }}
            size={12}
          />
        </p>
      </div>
    </div>
  );
};

export default Modal;
