import React from "react";
import { BiSolidCopy } from "react-icons/bi";
import Lottie from "lottie-react";
import game from "../animatioms/game.json";
const FriendsPage = () => {
  return (
    <div className="flex flex-col items-center px-4">
      <span className="absolute top-[10px] right-[10px] ">
        <Lottie
          autoplay={true}
          style={{
            width: "150px",

            zIndex: "-1",
            filter: "drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5))",
          }}
          animationData={game}
          loop={true}
        />
      </span>
      <div className="w-full felx flex-col items-start mt-10 border-l-[4px] border-[#EEB92E] px-2">
        <div className="text-[23px] font-[50]">
          <div className="flex items-center gap-1">
            <div className="font-[800]">Get 30% of</div> your
          </div>{" "}
          <div>Friends income</div>
        </div>
      </div>
      <div className="w-full relative bg-[#fff]/10 rounded-[23px] flex flex-col items-start px-4 py-4 mt-[80px]">
        <h3 className="border-sec-4 pl-5">Invite a friend</h3>
        <div className="text-[12px] font-[100] pl-4 flex items-center gap-2">
          <img className="w-[23px] h-[23px]" src="/img/coin.png" alt="" />
          <span className="bg-[#A009FF] rounded-2xl">+150000</span>
          <p>For you and your friend</p>
        </div>
        <button className="w-[60%] text-center bg-[#A009FF] text-[12px] py-2 rounded-xl mt-8">
          Invite a friend
        </button>
        <span className="bg-[#EEB92E] absolute bottom-4 right-4 p-2 rounded">
          <BiSolidCopy size={20} color="#450494" />
        </span>
      </div>
      <div className="w-full relative bg-[#fff]/10 rounded-[23px] flex flex-col items-start px-4 py-4 mt-[10px]">
        <h3 className="border-sec-4 pl-5">Premium</h3>
        <div className="text-[12px] font-[100] pl-4 flex items-center gap-2">
          <img className="w-[23px] h-[23px]" src="/img/coin.png" alt="" />
          <span className="bg-[#A009FF] rounded-2xl">+150000</span>
          <p>For you and your friend</p>
        </div>
        <button className="w-[60%] text-center bg-[#A009FF] text-[12px] py-2 rounded-xl mt-8">
          Invite a friend
        </button>
        <span className="bg-[#EEB92E] absolute bottom-4 right-4 p-2 rounded">
          <BiSolidCopy size={20} color="#450494" />
        </span>
      </div>
    </div>
  );
};

export default FriendsPage;
