import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { userContext } from "../context/userContext";
import { IoMdDoneAll } from "react-icons/io";
import { Link } from "react-router-dom";
import { httpReauest } from "../utils/httpRequest";
const TaskPage = () => {
  const [partner, setPartner] = useState("quest");
  const { data, setData, id, setId } = useContext(userContext);
  const [tasks, setTasks] = useState([]);
  // const tasks = [
  //   { name: "Event Agency", reward: "5k", lock: false },
  //   { name: "Influencer", reward: "1k", lock: false },
  //   { name: "Taxi", reward: "5k", lock: true, coin: 150 },
  //   { name: "Food shop", reward: "10k", lock: true, coin: 340 },
  //   { name: "Event Agency", reward: "30k", lock: true, coin: 480 },
  //   { name: "Event Agency", reward: "40k", lock: true, coin: 550 },
  // ];

  useEffect(() => {
    if (partner === "partners") {
      const filteredData = data?.tasks.filter(
        (item) => item.type === "partners"
      );
      setTasks(filteredData);
    } else if (partner === "quest") {
      const filteredData = data?.tasks.filter((item) => item.type === "daily");
      setTasks(filteredData);
    }
  }, [partner]);

  async function handleDaily(e, i) {
    if (e?.done) {
      return i.preventDefault();
    }
    console.log(e);
    const postId = await httpReauest(
      "POST",
      "/task",
      { userId: data?.user?._id, taskId: e?._id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            if (e.status === 200) {
              setData(e.data.data);

              setId(id);
            }
          }
        );
      }
    });
  }

  return (
    <div className="flex flex-col items-center px-4">
      <div className="text-[16px] bg-[#fff]/10  border-r border-[#fff]/40 rounded-xl p-4 mt-10 flex items-center gap-6 mx-2">
        <button
          onClick={() => setPartner("quest")}
          className={`px-8  border-r border-[#fff]/20  rounded-xl py-1 ${
            partner === "quest" ? "bg-[#C00EFF]" : "bg-[#fff]/15"
          }`}
        >
          Quest
        </button>
        <button
          onClick={() => setPartner("partners")}
          className={`px-4  border-r border-[#fff]/20 rounded-xl py-1 ${
            partner === "partners" ? "bg-[#C00EFF]" : "bg-[#fff]/15"
          }`}
        >
          Partners
        </button>
        <button
          onClick={() => setPartner("mine")}
          className={`px-4  border-r border-[#fff]/20 rounded-xl py-1 ${
            partner === "mine" ? "bg-[#C00EFF]" : "bg-[#fff]/15"
          }`}
        >
          Mining
        </button>
      </div>

      <div className="flex w-full flex-col items-center gap-2 mt-10">
        {tasks?.map((e) => (
          <Link
            target={"_blank"}
            onClick={() => handleDaily(e)}
            to={e?.url}
            className={`flex items-start justify-between  w-full p-2 border-[#fff]/40 rounded-2xl ${
              !e?.done ? "bg-[#fff]/15" : "bg-[#4D0A99]"
            }`}
          >
            <div className="flex items-start justify-start">
              <span
                className={`w-[65px] h-[65px] border-r border-[#fff]/40 rounded-2xl ${
                  !e?.done ? "bg-[#fff]/25" : "bg-[#3a017c]"
                }`}
              ></span>
              <div className="flex flex-col items-start my-2">
                <p className="text-[12px] border-sec-4 pl-5">{e?.content}</p>
                {/* <div className="flex items-center mx-3 gap-2">
                    <p className="text-[9px] bg-[#3c0081] rounded-2xl px-[4px]">
                      0 lvl
                    </p>
                    <div className="flex items-center">
                      <img className="w-[13px]" src="/img/coin.png" alt="" />
                      <p className="text-[9px]">+{e?.point}</p>
                    </div>
                  </div> */}
              </div>
            </div>
            {!e?.done ? (
              <button className="flex items-center px-5 bg-[#5b02a0] rounded-2xl py-1 mt-4 mx-2 pr-5">
                <img className="w-[9px]" src="/img/coin.png" alt="" />
                <p className="text-[10px] text-[#ffffff]/80">{e?.point}</p>
              </button>
            ) : (
              <div className="flex flex-col items-center mt-4 mx-4">
                <IoMdDoneAll size={20} />
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TaskPage;
