import React from "react";

const ChevronIcon = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.523 1.25034L8.25028 7.52309L1.99995 1.27277"
        stroke="white"
        stroke-width="3"
      />
    </svg>
  );
};

export default ChevronIcon;
