import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  return (
    <div className="grid grid-cols-4 justify-items-center fixed bottom-4  left-4 right-4 bg-[#821ff2] z-10 rounded-3xl py-2">
      <Link to={"/"} className={`flex flex-col items-center justify-end `}>
        <span className={`${location.pathname === "/" ? "absolute duration-200 bottom-[30px] border-[#40048A] border-[6px] px-2 py-2 rounded-full  bg-[#821ff2]" : ""}`}>
        <img  src="/img/home.png" alt="" />
        </span>
        <p className="text-[13px]">Home</p>
      </Link>
      <Link to={"/task"} className={`flex flex-col items-center justify-end `}>
        <span className={`${location.pathname === "/task" ? "absolute duration-200 bottom-[30px] border-[#40048A] border-[6px] px-2 py-2 rounded-full  bg-[#821ff2]" : ""}`}>
        <img  src="/img/task.png" alt="" />
        </span>
        <p className="text-[13px]">Task</p>
      </Link>
      <Link to={"/friends"} className={`flex flex-col items-center justify-end `}>
        <span className={`${location.pathname === "/friends" ? "absolute duration-200 bottom-[30px] border-[#40048A] border-[6px] px-2 py-2 rounded-full  bg-[#821ff2]" : ""}`}>
        <img  src="/img/friends.png" alt="" />
        </span>
        <p className="text-[13px]">Friends</p>
      </Link>
      <Link to={"/games"} className={`flex flex-col items-center justify-end `}>
        <span className={`${location.pathname === "/games" ? "absolute duration-200 bottom-[30px] border-[#40048A] border-[6px] px-2 py-2 rounded-full  bg-[#821ff2]" : ""}`}>
        <img  src="/img/games.png" alt="" />
        </span>
        <p className="text-[13px]">Mini games</p>
      </Link>
    </div>
  );
};

export default Navbar;
