import React from "react";

const Number = ({ number }) => {
  const newNumber = number.toFixed(3);
  const formatNumberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return <>{formatNumberWithCommas(newNumber)}</>;
};

export default Number;
