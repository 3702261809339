import { useEffect } from "react";
import { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./app.css";

import { userContext } from "./context/userContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import GamesPage from "./pages/GamesPage";
import FriendsPage from "./pages/FriendsPage";
import TaskPage from "./pages/TaskPage";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  return (
    <>
      <TonConnectUIProvider manifestUrl="https://lotton.app/tonconnect-manifest.json">
      <ToastContainer />
        <BrowserRouter>
          <ToastContainer />
          <userContext.Provider value={{ data, setData, id, setId }}>
            <img
              className="absolute top-[15%] left-0 right-0 bottom-0 -z-10"
              src="/img/yellow.png"
              alt=""
            />
            <Routes>
              <Route
                path="/"
                element={<Home setLoading={setLoading} loading={loading} />}
              />
              <Route
                path="/games"
                element={
                  <GamesPage setLoading={setLoading} loading={loading} />
                }
              />
              <Route
                path="/friends"
                element={
                  <FriendsPage setLoading={setLoading} loading={loading} />
                }
              />
              <Route
                path="/task"
                element={<TaskPage setLoading={setLoading} loading={loading} />}
              />
            </Routes>
            {!loading && <Navbar />}
          </userContext.Provider>
        </BrowserRouter>
      </TonConnectUIProvider>
    </>
  );
};

export default App;
