import React from "react";
import Countdown from "react-countdown";
import ChevronIcon from "../icon/ChevronIcon";
import Lottie from "lottie-react";
import chest from "../animatioms/chest.json";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import { httpReauest } from "../utils/httpRequest";
import { useEffect } from "react";
import Number from "../utils/Number";
import { useState } from "react";
import Modal from "../components/Modal";
import { useTonAddress, useTonConnectModal } from "@tonconnect/ui-react";
import Loading from "../components/Loading";
const Home = ({ loading, setLoading }) => {
  const { data, setData, id, setId } = useContext(userContext);
  const [searchParams] = useSearchParams();
  const [modal, setModal] = useState(false);
  const [lastrounds, setlastRounds] = useState([]);
  const [winner, setWinner] = useState([]);
  const [number, setNumber] = useState([]);
  const [number1, setNumber1] = useState([]);
  const [balance, setBalance] = useState(0);
  const { state, open, close } = useTonConnectModal();
  const userFriendlyAddress = useTonAddress();
  const [date, setDate] = useState();
  const [farmdate, setFarmdate] = useState();
  const [roundDetail, setRoundDetail] = useState([]);
  const [roundId, setRoundId] = useState();
  const [rounds, setRounds] = useState([]);
  const images = [
    null,
    "/biliard/1-removebg-preview.png",
    "/biliard/2-removebg-preview.png",
    "/biliard/3-removebg-preview.png",
    "/biliard/4-removebg-preview.png",
    "/biliard/5-removebg-preview.png",
    "/biliard/6-removebg-preview.png",
    "/biliard/7-removebg-preview.png",
    "/biliard/8-removebg-preview.png",
    "/biliard/9-removebg-preview.png",
  ];
  const [active, setActive] = useState([]);

  async function getActive(id) {
    const response = await httpReauest(
      "GET",
      `/round/active/${id}`,
      {},
      {}
    ).then((e) => {
      setActive(e?.data?.data);

      const newnumber = getImagesFromNumberString(e?.data?.data?.number);
      setNumber(newnumber);
      
    });
  }
  async function getRounds(id) {
    const response = await httpReauest("GET", `/round/${id}`, {}, {}).then(
      (e) => {
        console.log(e);

        setRounds(e?.data?.data);
        const yournumber = getImagesFromNumberString(e?.data?.data[0]?.number);
        setNumber1(yournumber);
      }
    );
  }
  async function getUser() {
    setLoading(true);
    if (id === "") {
      const user = searchParams.get("telegramId");
      const response = await httpReauest("GET", `/user/${user}`, {}, {}).then(
        (e) => {
          setId(user);
          setFarmdate(e?.data?.data?.user?.farm_date);
          setData(e?.data?.data);
          getLtt();
          lastRound(e?.data?.data?.user?._id);
          getRounds(e?.data?.data?.user?._id);
          getActive(e?.data?.data?.user?._id);
        }
      );
    } else {
      const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
        (e) => {
          setData(e?.data?.data);
          setFarmdate(e?.data?.data?.user?.farm_date);
          getLtt();
          getRounds(e?.data?.data?.user?._id);
          getActive(e?.data?.data?.user?._id);
        }
      );
    }
    setLoading(false);
  }
  async function getLtt() {
    const response = await httpReauest("GET", "/box", {}, {}).then((e) => {
      if (e.status === 200) {
        setBalance(e?.data?.data?.balance);
      }
    });
  }
  const getImagesFromNumberString = (numStr) => {
    // بررسی اینکه آیا رشته شش رقمی است
    if (numStr.length !== 6 || !/^\d+$/.test(numStr)) {
      throw new Error("لطفاً یک عدد شش رقمی وارد کنید.");
    }

    return Array.from(numStr).map((digit) => {
      const num = parseInt(digit, 10);
      return images[num]; // برگرداندن تصویر مربوط به عدد
    });
  };
  async function lastRound(id) {
    const response = await httpReauest("GET", `/round/last/${id}`, {}, {}).then(
      (e) => {
        if (e.status === 200) {
          setlastRounds(e?.data?.data);
          setRoundDetail(e?.data?.data);
          setRoundId(e?.data?.data?.id);
          const winner = getImagesFromNumberString(
            e?.data?.data?.winner_number
          );
          setWinner(winner);
          const initialDate = new Date(e?.data?.data?.createdAt);
          const targetDate = new Date(initialDate);
          targetDate.setDate(targetDate.getDate() + 1);
          setDate(targetDate);
          console.log(e);
        }
      }
    );
  }
  useEffect(() => {
    getUser();
  }, []);

  const Completionist = () => <span onClick={postFarm}>Start</span>;

  async function postFarm() {
    const response = await httpReauest(
      "POST",
      `/user/farm`,
      { telegramId: id },
      {}
    ).then((e) => {
      if (e.status === 201) {
        getUser();
      }
    });
  }

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span className="text-[10px] font-[100] tracking-widest">
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  const renderer2 = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <p className="text-[#EEB92E] text-[17px] font-[700]">
          {hours}h {minutes}m {seconds}s
        </p>
      );
    }
  };
  if (loading) {
    return <Loading h={"100px"} />;
  }
  return (
    <div
      onClick={() => setModal(false)}
      className="flex flex-col items-center px-4 max-h-[900px] overflow-hidden"
    >
      <h2 className="border-sec  text-[13px] font-[200] tracking-widest text-center mt-4">
        The Lotton Lottery
      </h2>

      <div className="flex justify-center items-center pr-4">
        <img className="w-[32px]" src="/img/tron.png" alt="" />
        <span className="text-[33px] font-[500] relative">
          <Number number={balance} />
          <span className="absolute  bg-[#8011ff] top-[35%] left-[0%] right-[0%] bottom-[35%] z-[-1] rounded-[34px]"></span>
        </span>
      </div>
      <span className="border-b  w-[160px] border-b-[#FFFFFF]/30 mt-2"></span>
      <h2 className="border-sec-2  text-[17px] font-[200] tracking-widest text-center flex items-center justify-center">
        Get your <p className="font-[600] mx-1">tickets</p> now
      </h2>
      <div className="flex flex-col items-center mt-2">
        <span className="mb-[-6px] relative z-10 rounded-full bg-[#5209a6] px-1 py-1">
          <ChevronIcon />
        </span>
        <button
          onClick={(e) => {
            setModal(true);
            e.stopPropagation();
          }}
          className="bg-[#6600da] rounded-[184px] px-4 py-1 text-[20px] font-[700] border-r border-[#b580f1]"
        >
          Buy Tickets
        </button>
        {active.number && (
          <div className="flex justify-center items-center mt-2 mb-2 gap-1">
            {number.map((e) => (
              <img className="w-[35px] " src={e} />
            ))}
          </div>
        )}
        <p className="text-[13px] font-[200] tracking-widest mb-[-10px] mt-1">
          Your LTT balance
        </p>
        <div className="flex fade justify-center items-center pr-4">
          <img className="w-[56px]" src="/img/lotton.png" alt="" />
          <span className="text-[33px] font-[500] relative">
            {data?.user?.balance}
            <span className="absolute  bg-[#8011ff] top-[35%] left-[0%] right-[0%] bottom-[35%] z-[-1] rounded-[34px]"></span>
          </span>
        </div>

        <div className="flex items-end gap-2 mt-1 justify-center">
          {date && (
            <Countdown date={new Date(date).getTime()} renderer={renderer2} />
          )}
          <p className="text-[13px] font-[200]">until the draw</p>
        </div>
        <span className="border-b-[6px] border-[#831cf9] w-[80px] rounded-lg mt-1 "></span>
        <Lottie
          autoplay={true}
          style={{
            width: "300px",
            marginTop:'-40px',
            zIndex: "-1",
            filter: "drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5))",
          }}
          animationData={chest}
          loop={true}
        />
      </div>
      <div className="flex flex-col items-center gap-3 w-full mb-[150px]">
        <div
          onClick={open}
          style={{
            background:
              "linear-gradient(90deg, rgba(130,31,242,1) 88%, rgba(165,45,251,1) 100%)",
          }}
          className="w-full text-[17px]  px-5 py-3 rounded-xl flex justify-between items-center"
        >
          <span className="border-sec-3 w-full ">Connect wallet</span>
          <span className="flex border-sec-4 items-center bg-[#620bc6] px-4 rounded-3xl ">
            {!userFriendlyAddress && <img src="/img/history.png" alt="" />}
            <p className="text-[10px]">
              {userFriendlyAddress
                ? `${userFriendlyAddress.slice(0, 13)}...`
                : "History"}
            </p>
          </span>
        </div>
        <div
          style={{
            background:
              "linear-gradient(90deg, rgba(130,31,242,1) 88%, rgba(165,45,251,1) 100%)",
          }}
          className="w-full text-[17px]  px-5 py-3 rounded-xl flex justify-between items-center"
        >
          <span className="border-sec-3 w-full ">Lotton farming 500ltt</span>

          {farmdate && (
            <Countdown
              date={new Date(farmdate).getTime() + 28800000}
              renderer={renderer}
            />
          )}
        </div>
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className={`absolute z-20  left-5 right-5 duration-300   ${
          modal ? "top-[60px]" : "top-[-100%]"
        }`}
      >
        <Modal
          userFriendlyAddress={userFriendlyAddress}
          getImagesFromNumberString={getImagesFromNumberString}
          setWinner={setWinner}
          setRoundId={setRoundId}
          roundId={roundId}
          setRoundDetail={setRoundDetail}
          roundDetail={roundDetail}
          winner={winner}
          number={number}
          lastrounds={lastrounds}
          data={data}
          modal={modal}
          getUser={getUser}
          open={open}
          rounds={rounds}
          active={active}
          setNumber1={setNumber1}
          number1={number1}
          setModal={setModal}
        />
      </div>
    </div>
  );
};

export default Home;
