import React from 'react'
import Lottie from "lottie-react";
import gift from "../animatioms/gift.json";
const GamesPage = () => {
    const games = [
        {name:"RocketX"},
        {name:"Speed & Cash"},
        {name:"Coinflip"},
        {name:"Lucky Jet"},
        {name:"Mines"},
        {name:"Rocket Queen"},
        {name:"Subway"},
        {name:"Empire"}
    ]
  return (
    <div className='flex flex-col items-center px-4 '>
      <Lottie
          autoplay={true}
          style={{
            width: "150px",
            position:"absolute",
            top:"5px",
            right:"5px",
            zIndex:"-1",
            filter: "drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.5))",
          }}
          animationData={gift}
          loop={true}
        />
      <div className='w-full felx flex-col items-start mt-10 border-l-[4px] border-[#EEB92E] px-2'>
          <h2 className='text-[23px]'>LOTTON GAMES</h2>
          <span className='text-[12px] font-[300] bg-[#5906AC] rounded-[23px]'>Play and earn tokens!</span>
      </div>
      <div className='relative grid grid-cols-2 w-full mt-10 gap-4'>
        
        {games.map((e)=>(
            <div className='w-full'>
            <div className='w-full h-[112px] rounded-[23px] flex justify-center items-center text-[25px] backdrop-blur-sm bg-[#fff]/20 '>Coming soon!</div>
            <span className='border-sec-4 pl-4 text-[16px]'>{e.name}</span>
         </div>
        ))}
      </div>
    </div>
  )
}

export default GamesPage
